import React from 'react';

import MailchimpSubscribe from "react-mailchimp-subscribe";

// import './mailform.css';
// import '../pages/tests.css';
import '../styles/newsletter.css';

const subscribeUrl = "//princeton.us7.list-manage.com/subscribe/post?u=1ed63aa66c770d4c2a680227d&amp;id=810178534b";

/**
 *  Mailchimp Subscribe Form
 */
export default class NewsBox extends React.Component {
  render() {
    return (
        <MailchimpSubscribe
            url={subscribeUrl}
            render={({ subscribe, status, message }) => (
                <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                />
            )}
        />
    );
  }
}

/* Form that takes in first name, last name, organization, and email */
const CustomForm = ({ status, message, onValidated }) => {
  let email, firstname, lastname, organization;
  const submit = () =>
    email &&
    firstname &&
    lastname &&
    email.value.indexOf("@") > -1 &&
    onValidated({  /* TAGS based on values listed in mailchimp settings */
      EMAIL: email.value,
      FNAME: firstname.value,
      LNAME: lastname.value,
      MMERGE6: organization.value
    });

  return (
    <div className="newsletter-subscribe-form">
      <div className='newsletter-form-inputs'>
        <input
          className='input'
          ref={node => (firstname = node)}
          type="text"
          placeholder="First name"
        />
        <input
          className='input'
          ref={node => (lastname = node)}
          type="text"
          placeholder="Last name"
        />
        <input
          className='input'
          ref={node => (email = node)}
          type="email"
          placeholder="Email"
        />
        <input
          className='input'
          ref={node => (organization = node)}
          type="text"
          placeholder="Organization (optional)"
        />
        <br/>
        <button id='newsletter-submit-button' className='button' onClick={submit}>
        Subscribe
        </button>
        
        {status === "sending" && <div className='sending'>Sending...</div>}
        {status === "error" && (
          <div className='error' dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === "success" && (
          <div className='success' dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </div>
  );
};